import React, { useEffect, useState } from 'react'
import Sparta from './Sparta'
import FastRanking from './FastRanking'
import Vee from './Vee'
import Screen_1 from './Screen_1'

function AllProcesses() {
    const [slide, setSlide] = useState('0');
    useEffect(() => {
        setTimeout(() => {
            setSlide(Number(slide+100));
            if(slide > 200){
                setSlide('0')
            }
        }, slide > 0 ? 13000 : 4000)
    }) 
  return (
    <div className='h-full w-full overflow-hidden'>
        <div className='w-full h-full flex transition-md' style={{marginLeft: `-${slide}%`}}>
            <div className='min-w-full'><Screen_1 theme="purple" /></div>
            <div className='min-w-full'><FastRanking theme="purple" /></div>
            <div className='min-w-full'><Sparta theme="blue" /></div>
            <div className='min-w-full'><Vee theme="sky-blue" /></div>
        </div>
    </div>
  )
}

export default AllProcesses