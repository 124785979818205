import React, { useEffect, useState } from 'react'
import mainLogo from '../Assets/Logos/mahakaya.svg'
import mainLogoLight from '../Assets/Logos/mahakaya-white.svg'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getByDisplayValue } from '@testing-library/react';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../States/ThemeSlice';

function Navigation(props) {
    const savedTheme = useSelector((state) => state.darkTheme);
    // Dark = true
    // Light = false
    const {processName} = useParams();
    const [themeMode, setThemeMode] = useState(false);
    const dispatch = useDispatch();

    const handleTheme = (e) => {
        setThemeMode(themeMode ? !themeMode : true);
        localStorage.setItem('theme', themeMode);
        dispatch(setTheme(themeMode));
    }
  return (
    <div className={`${savedTheme ? 'bg-neutral-800' : 'bg-white border-b'} p-4 flex justify-between items-center h-16`}>
        <div className='w-1/4 pl-10'><Link to={'/'}><img src={savedTheme ? mainLogoLight : mainLogo} width={'140px'} /></Link></div>
        <div className='w-2/4 flex justify-center items-center'>
            <span className='cursor-pointer' onClick={handleTheme}>
                {
                    savedTheme ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24">
                        <path fill="white" d="M18 12a6 6 0 1 1-12 0a6 6 0 0 1 12 0" />
                        <path fill="white" fillRule="evenodd" d="M12 1.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75M4.399 4.399a.75.75 0 0 1 1.06 0l.393.392a.75.75 0 0 1-1.06 1.061l-.393-.393a.75.75 0 0 1 0-1.06m15.202 0a.75.75 0 0 1 0 1.06l-.393.393a.75.75 0 0 1-1.06-1.06l.393-.393a.75.75 0 0 1 1.06 0M1.25 12a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75m19 0a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1-.75-.75m-2.102 6.148a.75.75 0 0 1 1.06 0l.393.393a.75.75 0 1 1-1.06 1.06l-.393-.393a.75.75 0 0 1 0-1.06m-12.296 0a.75.75 0 0 1 0 1.06l-.393.393a.75.75 0 1 1-1.06-1.06l.392-.393a.75.75 0 0 1 1.061 0M12 20.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75" clipRule="evenodd" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24">
                        <path fill="black" d="M18 12a6 6 0 1 1-12 0a6 6 0 0 1 12 0" />
                        <path fill="black" fillRule="evenodd" d="M12 1.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75M4.399 4.399a.75.75 0 0 1 1.06 0l.393.392a.75.75 0 0 1-1.06 1.061l-.393-.393a.75.75 0 0 1 0-1.06m15.202 0a.75.75 0 0 1 0 1.06l-.393.393a.75.75 0 0 1-1.06-1.06l.393-.393a.75.75 0 0 1 1.06 0M1.25 12a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75m19 0a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1-.75-.75m-2.102 6.148a.75.75 0 0 1 1.06 0l.393.393a.75.75 0 1 1-1.06 1.06l-.393-.393a.75.75 0 0 1 0-1.06m-12.296 0a.75.75 0 0 1 0 1.06l-.393.393a.75.75 0 1 1-1.06-1.06l.392-.393a.75.75 0 0 1 1.061 0M12 20.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75" clipRule="evenodd" />
                    </svg>
                }
            </span>
        </div>
        {/* <img src='' />  */}
            <div className='w-1/4 h-12 max-h-12 flex justify-end'><img src={savedTheme ? props.logoLite : props.logo} width={props.width} /></div>
        {/* <span>
            dd
        </span> */}
    </div>
  )
}

export default Navigation