import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'

function Layout() {
    const savedTheme = useSelector((state) => state.darkTheme);
    setTimeout(() => {
        //window.location.reload();
    }, 39000)
    return (
        <div className={`min-h-screen overflow-hidden ${savedTheme ? 'bg-black text-white' : 'bg-white'}`}><Outlet /></div>
    )
}

export default Layout