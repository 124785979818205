import React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';

function Ranks(props) {
    const savedTheme = useSelector((state) => state.darkTheme);
    const [fr_totals, setFRTotals] = useState();
    const [sp_totals, setSPTotals] = useState();
    const [ve_totals, setVETotals] = useState()
    useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const [frResponse, spResponse, veResponse] = await Promise.all([
          axios.get("https://scorboardapi.fastranking.tech/total_fastranking"),
          axios.get("https://scorboardapi.fastranking.tech/total_sparta"),
          axios.get("https://scorboardapi.fastranking.tech/total_v_repair")
        ]);

        setFRTotals(frResponse.data.data);
        setSPTotals(spResponse.data.data);
        setVETotals(veResponse.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    // Initial fetch
    fetchData();

    // Set up interval
    const intervalId = setInterval(fetchData, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
    if (props.theme === "purple") {
        return (
            <div className={`flex justify-center items-end mt-36 relative`}>
          		<div className='w-full flex justify-end overflow-hidden absolute -top-28 right-0'>
                    <div className='inline-block p-5 px-10 rounded-2xl rounded-r-none bg-purple-600 text-white fastranking-total-score'><span className='text-xl h-10 inline-block'>Total:</span> <br /> <span className='text-3xl inline-block h-10 -mt-10'>{fr_totals} Leads</span></div>
                </div>
                {/* Rank 2 */}
                <div className={`h-32 min-w-60 max-w-60 px-10 rounded-xl  relative bg-gradient-to-b ${savedTheme ? 'from-purple-800 via-neutral-950 to-black text-white' : 'from-purple-100 via-white to-white text-black'}`}>
                    <div className='-mt-8 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-purple-700 bg-purple-600' : 'border-purple-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-8 inline-flex justify-center'>
                                {/* <img src={crown} width={'40px'} /> */}
                                <svg width="43" height="28" viewBox="0 0 43 28" fill="#9333ea" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.5374 11.8205C37.1968 11.6028 36.7832 11.4628 36.3437 11.4164C35.9042 11.37 35.4565 11.4192 35.0518 11.5584L27.5206 14.1232L23.1448 8.08155C22.9357 7.79948 22.6327 7.56491 22.2666 7.40172C21.9006 7.23853 21.4846 7.15256 21.0611 7.15256C20.6376 7.15256 20.2216 7.23853 19.8556 7.40172C19.4896 7.56491 19.1865 7.79948 18.9774 8.08155L14.6016 14.1232L7.07044 11.5584C6.66487 11.4194 6.21664 11.3702 5.77643 11.4162C5.33622 11.4621 4.92158 11.6015 4.57938 11.8185C4.23718 12.0355 3.98108 12.3216 3.84002 12.6442C3.69895 12.9669 3.67856 13.3134 3.78114 13.6444L7.5616 25.99C7.63389 26.229 7.76878 26.4542 7.9581 26.6522C8.14742 26.8501 8.38723 27.0165 8.663 27.1413C9.03635 27.3125 9.46322 27.4031 9.89834 27.4035C10.1099 27.4032 10.3203 27.3801 10.5235 27.3351C17.4143 25.8759 24.693 25.8759 31.5839 27.3351C32.2131 27.4618 32.8822 27.3921 33.4444 27.1413C33.7218 27.0181 33.9629 26.8521 34.1525 26.654C34.3421 26.4558 34.476 26.2297 34.5458 25.99L38.3411 13.6444C38.4425 13.3133 38.421 12.967 38.279 12.6447C38.137 12.3224 37.8801 12.0369 37.5374 11.8205Z" fill="#9333ea"/>
                                    <ellipse cx="21.0598" cy="4.0872" rx="4.95532" ry="4.0872" fill="#9333ea"/>
                                    <ellipse cx="39.0229" cy="10.7288" rx="3.09707" ry="2.5545" fill="#9333ea"/>
                                    <ellipse cx="3.09707" cy="10.7288" rx="3.09707" ry="2.5545" fill="#9333ea"/>
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center ${savedTheme ? 'bg-purple-700 text-white' : 'bg-purple-50 text-purple-600'} h-16 w-16 rounded-full text-5xl font-bold`}>2</span>
                        </span>
                    </div>

                    <div className='text-xl mt-4 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[1].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-purple-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[1].sales_score} ${props.renderer === "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>

                {/* Rank 1 */}
                <div className={`h-40 mx-8 w-72 rounded-xl relative bg-gradient-to-b -mt-6 ${savedTheme ? 'from-purple-800 via-neutral-950 to-black text-white' : 'from-purple-100 via-white to-white'}`}>
                    <div className='-mt-14 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-purple-900 bg-purple-600' : 'border-purple-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-12 inline-flex justify-center'>
                                {/* <img src={trophy} width={'40px'} /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 128 128">
                                    <path fill="#fec417" d="M97.12 59.35c2.22-1.83 15.04-9.76 22.06-16.44c5.48-5.22 7.6-18.85-.83-25.48c-10.96-8.61-21.48.43-21.48.43s-.92-5.11-7.32-8.11c-8.06-3.78-18.27-5.19-27.66-5.07c-9.1.11-17.48 1.21-24.92 4.86c-6.66 3.27-7.06 7.93-7.06 7.93s-8.74-8.35-19.45-1.57C-.24 22.69 2.83 36.29 7.98 42c6.71 7.44 14.77 11.23 18.43 13.97c3.65 2.74 7.15 5.35 7.15 7.7s-1.04 2.87-1.44 2.74c-.39-.13-1.08-2.42-2.87-1.44c-2.44 1.35-1.7 6.79 3.39 7.31c4.93.51 5.87-4.7 5.87-4.7l.78-5.09l8.09 5.61l8.87 7.31l-.26 6.26s-.39 4.83-2.74 9.01s-6.13 8.35-6.13 8.35l-.12 3.78l32.89-.78l-1.04-3.65s-4.24-4.66-6.79-9.66c-1.82-3.58-2.11-7.19-2.11-7.19l-.08-8.98l17.07-10.46s1.83 1.44 1.57 1.96s-.32 5.85 3.78 7.83c4.05 1.96 7.31-.52 6.92-3.92c-.39-3.39-2.48-2.22-3.13-1.7s-2.09.65-2.48-1.31c-.4-1.94 1.3-3.77 3.52-5.6M13.09 40.89l-3.91-11.9l4.73-6.19l5.3-1.55s6.77 5.05 7.01 5.46s4.32 8.8 4.32 8.8l5.38 19.48zm76.86 15.65c.08-.41 5.05-20.87 5.05-20.87c0-.24 3.5-1.87 3.5-2.2s6.36-10.68 6.36-10.68l10.6.41l2.2 10.51l-5.3 8.72c.01.01-22.49 14.51-22.41 14.11" />
                                    <path fill="#ffa828" d="M119.92 30.64c-.78-9.32-10-11.55-13.79-10.2s-6.25 3.88-7.09 7.48c-.68 2.91-1.36 4.85-2.14 5.15c-.86.32-1.65.19-1.65.19s-.14-6.3-.1-8.84c.1-5.24 1.7-6.58 1.7-6.58s-.34-1.77-1.02-2.75c-.68-.97-1.94-2.23-1.94-2.23s.29 2.52-.97 3.11s-2.62-.29-2.62-.29l1.26 5.24s-2.04 26.12-2.23 26.7s-7.86 13.88-7.86 13.88s-4.47 4.18-6.6 5.15c-2.14.97-3.98 3.5-12.23 3.59c-8.25.1-15.34-6.41-15.34-6.41l-7.09-6.8l-5.24-15.44l-.78-15.73l.33-5.72l.23-2.39l-.37-1.21s-.68.68-1.94.39s-1.75-1.94-1.75-1.94s-.49.78-.58 1.17c-.1.39-.2 1.29-.2 1.29s1.36 1.43 1.65 3.47s-.1 10.49-1.55 10.29c-1.46-.19-.98-7.28-6.51-10c-4.79-2.36-11.94-1.65-14.86 4.27c-2.91 5.92-.78 12.91 8.35 19.42s18.85 11.98 20.39 17.2c1.84 6.21-2.33 9.9-2.33 9.9s3.03-.42 4.52-3.67c.48-1.05.66-2.58.73-3.49c3.45 4.5 8.04 8.31 14.12 10.15c2.3.79 4.89 1.19 8.49 1.19c.52 0 1.03-.02 1.54-.04c4.21-.15 7.81-.94 11.3-2.67c4.71-2.18 8.36-5.51 11.18-9.29c-.08.9.47 3.14 1.52 4.91c1.84 3.11 4.95 3.3 4.95 3.3s-4.27-2.52-3.4-8.54s4.85-8.16 10.87-12.04c6.04-3.89 19.82-11.85 19.05-21.17M21.27 43.85c-2.71-1.91-7.46-4.46-9.42-8.35c-2.61-5.19-.49-11.46 4.47-12.23c10.3-1.62 10.49 11.17 11.17 12.72s2.62 1.65 2.62 1.65s.07.86.35 2.52c.01.05.02.09.02.14l.03.15c.19 1.13.46 2.54.84 4.15l.03.12c.21.91.43 1.77.66 2.56c.46 1.66 1.02 3.45 1.71 5.29c-1-.68-6.73-4.65-12.48-8.72m93.31-7.19c-2.15 3.48-6.5 6.07-9.71 8.25c-4.14 2.81-10.3 7.03-12.04 8.21c.4-1.06.75-2.11 1.07-3.14c.52-1.36 1.07-3.02 1.45-4.81c1-4.7 1.17-8.81 1.17-8.81s2.04.58 3.3-.78s1.73-5.58 2.91-7.57c1.84-3.11 5.53-6.31 11.07-2.43c3.2 2.25 3.32 6.96.78 11.08M60.52 79.31l5.65-.07s.15 6.17 1.04 10.77c.89 4.61 2.08 9.73 2.08 9.73s-11.51 3.86-11.44-.3s1.78-12.26 1.78-12.26s1.04-9.65.89-7.87" />
                                    <path fill="#ffa828" d="M51.04 15.19s6.29-.71 12.14-.62s11.87 1.24 11.87 1.24s-.35 19.58-1.42 30.57c-1.06 10.99-5.22 19.22-6.33 21.55c-1.07 2.26-1.85 3.47-1.85 3.47l-5.64-.66l-9.75-19.58z" />
                                    <path fill="#ffefab" d="M43.51 18.12s1.77-.89 4.34-1.86c2.33-.88 4.43-1.15 4.43-1.15s.06 12.98.27 22.24c.18 7.89 3.1 20.82 4.52 24.28s3.98 8.74 3.98 8.74s-5.15-.74-8.32-4.76c-1.67-2.12-9.48-18.52-9.75-29.33s.79-18.43.53-18.16" />
                                    <path fill="#fffcfd" d="M40.58 19.62c-.18.53-1.59 11.7.62 22.06s5.48 17.45 7.64 20.26c1.92 2.49 3.17 3.67 3.88 3.67s-2.92-8.59-4.61-15.33c-1.68-6.73-2.57-14.8-2.75-18.78c-.18-3.99-.35-14.27-.35-14.27s-1.95.89-2.39 1.15c-.44.27-2.04 1.24-2.04 1.24" />
                                    <path fill="#ffefab" d="M53.34 99.01s3.81-6.2 4.61-10.9s1.24-8.86 1.24-8.86l2.36.1s-.23 6.81-.5 11.42s-1.06 9.13-1.06 9.13z" />
                                    <path fill="#d1701c" d="M56.27 75.41s1.77-1.73 6.64-1.73s6.96 1.87 6.96 1.87l.13 6.01s-3.24-1.01-7.18-1.06c-3.28-.04-6.81 1.18-6.81 1.18zM35 15.72c.82-.76 8.68-6.66 27.29-6.82c20.02-.18 27.53 5.62 28 5.94c.53.35 1.15 1.95.89 2.66c-.27.71-.8.97-2.3.35c-1.51-.62-8.95-6.05-26.85-5.49c-17.19.53-24.48 5.76-25.25 6.2c-.62.35-1.77.44-2.3-.27c-.54-.7-.01-2.07.52-2.57m56.18 4.17c-2.3.18-.71 18.7-5.58 30.3c-5.22 12.41-11.88 15.95-11.61 16.66s6.79-1.26 10.9-7.09c3.81-5.4 6.11-10.19 7.18-16.57c1.05-6.38 1.67-23.5-.89-23.3m-56.67 2.12c-.58-.03.01 9.21-.93 13.27c-.36 1.56-1.24 1.88-1.33 2.23s1.93 12.24 5.32 17.46c3.28 5.05 9.75 11.34 10.81 10.28s-4.7-4.61-8.59-12.32c-2.24-4.42-4.52-11.08-4.61-18.7c-.06-5.04.57-12.15-.67-12.22" />
                                    <path fill="#865c50" d="m86.77 112.71l-.01-14.29H40.89c.14 0 .03 11.62.01 14.34h-6.52v10.35h58.25v-10.35z" />
                                    <path fill="#fcc219" d="M55.59 105.93c-1.13-.05-2.43.18-2.48 1.58s-.05 5.45-.05 6.22s.63 1.44 2.21 1.49s16.41 0 17.45 0s1.58-.9 1.62-1.8c.05-.9-.05-4.69-.05-5.72c0-1.71-1.53-1.71-2.57-1.71c-1.12-.02-16.13-.06-16.13-.06" />
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center h-24 w-24 rounded-full text-6xl font-bold ${savedTheme ? 'bg-purple-700 text-white' : 'bg-purple-50 text-purple-600'}`}>1</span>
                        </span>
                    </div>

                    <div className='text-3xl mt-6 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[0].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-purple-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[0].sales_score} ${props.renderer === "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>

                {/* Rank 3 */}
                <div className={`h-28 min-w-60 max-w-60 px-10 rounded-xl  relative bg-gradient-to-b ${savedTheme ? 'from-purple-800 via-neutral-950 to-black text-white' : 'from-purple-100 via-white to-white'}`}>
                    <div className='-mt-8 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-purple-900 bg-purple-600' : 'border-purple-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-8 inline-flex justify-center'>
                                {/* <img src={crown} width={'40px'} /> */}
                                <svg width="43" height="28" viewBox="0 0 43 28" fill="#9333ea" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.5374 11.8205C37.1968 11.6028 36.7832 11.4628 36.3437 11.4164C35.9042 11.37 35.4565 11.4192 35.0518 11.5584L27.5206 14.1232L23.1448 8.08155C22.9357 7.79948 22.6327 7.56491 22.2666 7.40172C21.9006 7.23853 21.4846 7.15256 21.0611 7.15256C20.6376 7.15256 20.2216 7.23853 19.8556 7.40172C19.4896 7.56491 19.1865 7.79948 18.9774 8.08155L14.6016 14.1232L7.07044 11.5584C6.66487 11.4194 6.21664 11.3702 5.77643 11.4162C5.33622 11.4621 4.92158 11.6015 4.57938 11.8185C4.23718 12.0355 3.98108 12.3216 3.84002 12.6442C3.69895 12.9669 3.67856 13.3134 3.78114 13.6444L7.5616 25.99C7.63389 26.229 7.76878 26.4542 7.9581 26.6522C8.14742 26.8501 8.38723 27.0165 8.663 27.1413C9.03635 27.3125 9.46322 27.4031 9.89834 27.4035C10.1099 27.4032 10.3203 27.3801 10.5235 27.3351C17.4143 25.8759 24.693 25.8759 31.5839 27.3351C32.2131 27.4618 32.8822 27.3921 33.4444 27.1413C33.7218 27.0181 33.9629 26.8521 34.1525 26.654C34.3421 26.4558 34.476 26.2297 34.5458 25.99L38.3411 13.6444C38.4425 13.3133 38.421 12.967 38.279 12.6447C38.137 12.3224 37.8801 12.0369 37.5374 11.8205Z" fill="#9333ea"/>
                                    <ellipse cx="21.0598" cy="4.0872" rx="4.95532" ry="4.0872" fill="#9333ea"/>
                                    <ellipse cx="39.0229" cy="10.7288" rx="3.09707" ry="2.5545" fill="#9333ea"/>
                                    <ellipse cx="3.09707" cy="10.7288" rx="3.09707" ry="2.5545" fill="#9333ea"/>
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center ${savedTheme ? 'bg-purple-700 text-white' : 'bg-purple-50 text-purple-600'} h-16 w-16 rounded-full text-5xl font-bold`}>3</span>
                        </span>
                    </div>

                    <div className='text-xl mt-2 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[2].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-purple-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[2].sales_score} ${props.renderer === "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>
            </div>
        )
    }

    // Sparta Telecom
    if (props.theme === "blue") {
        return (
            <div className={`flex justify-center items-end mt-36 relative`}>
				<div className='w-full flex justify-end overflow-hidden absolute -top-28 right-0'>
                    <div className='inline-block p-5 px-10 rounded-2xl rounded-r-none bg-blue-600 text-white sparta-total-score'><span className='text-xl h-10 inline-block'>Total:</span> <br /> <span className='text-3xl inline-block h-10 -mt-10'>{sp_totals} Sales</span></div>
                </div>
                {/* Rank 2 */}
                <div className={`h-32 min-w-60 max-w-60 px-10 rounded-xl  relative bg-gradient-to-b ${savedTheme ? 'from-blue-800 via-neutral-950 to-black text-white' : 'from-blue-100 via-white to-white text-black'}`}>
                    <div className='-mt-8 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-blue-700 bg-blue-600' : 'border-blue-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-8 inline-flex justify-center'>
                                {/* <img src={crown} width={'40px'} /> */}
                                <svg width="43" height="28" viewBox="0 0 43 28" fill="#2563eb" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.5374 11.8205C37.1968 11.6028 36.7832 11.4628 36.3437 11.4164C35.9042 11.37 35.4565 11.4192 35.0518 11.5584L27.5206 14.1232L23.1448 8.08155C22.9357 7.79948 22.6327 7.56491 22.2666 7.40172C21.9006 7.23853 21.4846 7.15256 21.0611 7.15256C20.6376 7.15256 20.2216 7.23853 19.8556 7.40172C19.4896 7.56491 19.1865 7.79948 18.9774 8.08155L14.6016 14.1232L7.07044 11.5584C6.66487 11.4194 6.21664 11.3702 5.77643 11.4162C5.33622 11.4621 4.92158 11.6015 4.57938 11.8185C4.23718 12.0355 3.98108 12.3216 3.84002 12.6442C3.69895 12.9669 3.67856 13.3134 3.78114 13.6444L7.5616 25.99C7.63389 26.229 7.76878 26.4542 7.9581 26.6522C8.14742 26.8501 8.38723 27.0165 8.663 27.1413C9.03635 27.3125 9.46322 27.4031 9.89834 27.4035C10.1099 27.4032 10.3203 27.3801 10.5235 27.3351C17.4143 25.8759 24.693 25.8759 31.5839 27.3351C32.2131 27.4618 32.8822 27.3921 33.4444 27.1413C33.7218 27.0181 33.9629 26.8521 34.1525 26.654C34.3421 26.4558 34.476 26.2297 34.5458 25.99L38.3411 13.6444C38.4425 13.3133 38.421 12.967 38.279 12.6447C38.137 12.3224 37.8801 12.0369 37.5374 11.8205Z" fill="#2563eb"/>
                                    <ellipse cx="21.0598" cy="4.0872" rx="4.95532" ry="4.0872" fill="#2563eb"/>
                                    <ellipse cx="39.0229" cy="10.7288" rx="3.09707" ry="2.5545" fill="#2563eb"/>
                                    <ellipse cx="3.09707" cy="10.7288" rx="3.09707" ry="2.5545" fill="#2563eb"/>
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center ${savedTheme ? 'bg-blue-700 text-white' : 'bg-blue-50 text-blue-600'} h-16 w-16 rounded-full text-5xl font-bold`}>2</span>
                        </span>
                    </div>

                    <div className='text-xl mt-4 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[1].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-blue-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[1].sales_score} ${props.renderer == "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>

                {/* Rank 1 */}
                <div className={`h-40 mx-8 w-72 rounded-xl relative bg-gradient-to-b -mt-6 ${savedTheme ? 'from-blue-800 via-neutral-950 to-black text-white' : 'from-blue-100 via-white to-white'}`}>
                    <div className='-mt-14 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-blue-900 bg-blue-600' : 'border-blue-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-12 inline-flex justify-center'>
                                {/* <img src={trophy} width={'40px'} /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 128 128">
                                    <path fill="#fec417" d="M97.12 59.35c2.22-1.83 15.04-9.76 22.06-16.44c5.48-5.22 7.6-18.85-.83-25.48c-10.96-8.61-21.48.43-21.48.43s-.92-5.11-7.32-8.11c-8.06-3.78-18.27-5.19-27.66-5.07c-9.1.11-17.48 1.21-24.92 4.86c-6.66 3.27-7.06 7.93-7.06 7.93s-8.74-8.35-19.45-1.57C-.24 22.69 2.83 36.29 7.98 42c6.71 7.44 14.77 11.23 18.43 13.97c3.65 2.74 7.15 5.35 7.15 7.7s-1.04 2.87-1.44 2.74c-.39-.13-1.08-2.42-2.87-1.44c-2.44 1.35-1.7 6.79 3.39 7.31c4.93.51 5.87-4.7 5.87-4.7l.78-5.09l8.09 5.61l8.87 7.31l-.26 6.26s-.39 4.83-2.74 9.01s-6.13 8.35-6.13 8.35l-.12 3.78l32.89-.78l-1.04-3.65s-4.24-4.66-6.79-9.66c-1.82-3.58-2.11-7.19-2.11-7.19l-.08-8.98l17.07-10.46s1.83 1.44 1.57 1.96s-.32 5.85 3.78 7.83c4.05 1.96 7.31-.52 6.92-3.92c-.39-3.39-2.48-2.22-3.13-1.7s-2.09.65-2.48-1.31c-.4-1.94 1.3-3.77 3.52-5.6M13.09 40.89l-3.91-11.9l4.73-6.19l5.3-1.55s6.77 5.05 7.01 5.46s4.32 8.8 4.32 8.8l5.38 19.48zm76.86 15.65c.08-.41 5.05-20.87 5.05-20.87c0-.24 3.5-1.87 3.5-2.2s6.36-10.68 6.36-10.68l10.6.41l2.2 10.51l-5.3 8.72c.01.01-22.49 14.51-22.41 14.11" />
                                    <path fill="#ffa828" d="M119.92 30.64c-.78-9.32-10-11.55-13.79-10.2s-6.25 3.88-7.09 7.48c-.68 2.91-1.36 4.85-2.14 5.15c-.86.32-1.65.19-1.65.19s-.14-6.3-.1-8.84c.1-5.24 1.7-6.58 1.7-6.58s-.34-1.77-1.02-2.75c-.68-.97-1.94-2.23-1.94-2.23s.29 2.52-.97 3.11s-2.62-.29-2.62-.29l1.26 5.24s-2.04 26.12-2.23 26.7s-7.86 13.88-7.86 13.88s-4.47 4.18-6.6 5.15c-2.14.97-3.98 3.5-12.23 3.59c-8.25.1-15.34-6.41-15.34-6.41l-7.09-6.8l-5.24-15.44l-.78-15.73l.33-5.72l.23-2.39l-.37-1.21s-.68.68-1.94.39s-1.75-1.94-1.75-1.94s-.49.78-.58 1.17c-.1.39-.2 1.29-.2 1.29s1.36 1.43 1.65 3.47s-.1 10.49-1.55 10.29c-1.46-.19-.98-7.28-6.51-10c-4.79-2.36-11.94-1.65-14.86 4.27c-2.91 5.92-.78 12.91 8.35 19.42s18.85 11.98 20.39 17.2c1.84 6.21-2.33 9.9-2.33 9.9s3.03-.42 4.52-3.67c.48-1.05.66-2.58.73-3.49c3.45 4.5 8.04 8.31 14.12 10.15c2.3.79 4.89 1.19 8.49 1.19c.52 0 1.03-.02 1.54-.04c4.21-.15 7.81-.94 11.3-2.67c4.71-2.18 8.36-5.51 11.18-9.29c-.08.9.47 3.14 1.52 4.91c1.84 3.11 4.95 3.3 4.95 3.3s-4.27-2.52-3.4-8.54s4.85-8.16 10.87-12.04c6.04-3.89 19.82-11.85 19.05-21.17M21.27 43.85c-2.71-1.91-7.46-4.46-9.42-8.35c-2.61-5.19-.49-11.46 4.47-12.23c10.3-1.62 10.49 11.17 11.17 12.72s2.62 1.65 2.62 1.65s.07.86.35 2.52c.01.05.02.09.02.14l.03.15c.19 1.13.46 2.54.84 4.15l.03.12c.21.91.43 1.77.66 2.56c.46 1.66 1.02 3.45 1.71 5.29c-1-.68-6.73-4.65-12.48-8.72m93.31-7.19c-2.15 3.48-6.5 6.07-9.71 8.25c-4.14 2.81-10.3 7.03-12.04 8.21c.4-1.06.75-2.11 1.07-3.14c.52-1.36 1.07-3.02 1.45-4.81c1-4.7 1.17-8.81 1.17-8.81s2.04.58 3.3-.78s1.73-5.58 2.91-7.57c1.84-3.11 5.53-6.31 11.07-2.43c3.2 2.25 3.32 6.96.78 11.08M60.52 79.31l5.65-.07s.15 6.17 1.04 10.77c.89 4.61 2.08 9.73 2.08 9.73s-11.51 3.86-11.44-.3s1.78-12.26 1.78-12.26s1.04-9.65.89-7.87" />
                                    <path fill="#ffa828" d="M51.04 15.19s6.29-.71 12.14-.62s11.87 1.24 11.87 1.24s-.35 19.58-1.42 30.57c-1.06 10.99-5.22 19.22-6.33 21.55c-1.07 2.26-1.85 3.47-1.85 3.47l-5.64-.66l-9.75-19.58z" />
                                    <path fill="#ffefab" d="M43.51 18.12s1.77-.89 4.34-1.86c2.33-.88 4.43-1.15 4.43-1.15s.06 12.98.27 22.24c.18 7.89 3.1 20.82 4.52 24.28s3.98 8.74 3.98 8.74s-5.15-.74-8.32-4.76c-1.67-2.12-9.48-18.52-9.75-29.33s.79-18.43.53-18.16" />
                                    <path fill="#fffcfd" d="M40.58 19.62c-.18.53-1.59 11.7.62 22.06s5.48 17.45 7.64 20.26c1.92 2.49 3.17 3.67 3.88 3.67s-2.92-8.59-4.61-15.33c-1.68-6.73-2.57-14.8-2.75-18.78c-.18-3.99-.35-14.27-.35-14.27s-1.95.89-2.39 1.15c-.44.27-2.04 1.24-2.04 1.24" />
                                    <path fill="#ffefab" d="M53.34 99.01s3.81-6.2 4.61-10.9s1.24-8.86 1.24-8.86l2.36.1s-.23 6.81-.5 11.42s-1.06 9.13-1.06 9.13z" />
                                    <path fill="#d1701c" d="M56.27 75.41s1.77-1.73 6.64-1.73s6.96 1.87 6.96 1.87l.13 6.01s-3.24-1.01-7.18-1.06c-3.28-.04-6.81 1.18-6.81 1.18zM35 15.72c.82-.76 8.68-6.66 27.29-6.82c20.02-.18 27.53 5.62 28 5.94c.53.35 1.15 1.95.89 2.66c-.27.71-.8.97-2.3.35c-1.51-.62-8.95-6.05-26.85-5.49c-17.19.53-24.48 5.76-25.25 6.2c-.62.35-1.77.44-2.3-.27c-.54-.7-.01-2.07.52-2.57m56.18 4.17c-2.3.18-.71 18.7-5.58 30.3c-5.22 12.41-11.88 15.95-11.61 16.66s6.79-1.26 10.9-7.09c3.81-5.4 6.11-10.19 7.18-16.57c1.05-6.38 1.67-23.5-.89-23.3m-56.67 2.12c-.58-.03.01 9.21-.93 13.27c-.36 1.56-1.24 1.88-1.33 2.23s1.93 12.24 5.32 17.46c3.28 5.05 9.75 11.34 10.81 10.28s-4.7-4.61-8.59-12.32c-2.24-4.42-4.52-11.08-4.61-18.7c-.06-5.04.57-12.15-.67-12.22" />
                                    <path fill="#865c50" d="m86.77 112.71l-.01-14.29H40.89c.14 0 .03 11.62.01 14.34h-6.52v10.35h58.25v-10.35z" />
                                    <path fill="#fcc219" d="M55.59 105.93c-1.13-.05-2.43.18-2.48 1.58s-.05 5.45-.05 6.22s.63 1.44 2.21 1.49s16.41 0 17.45 0s1.58-.9 1.62-1.8c.05-.9-.05-4.69-.05-5.72c0-1.71-1.53-1.71-2.57-1.71c-1.12-.02-16.13-.06-16.13-.06" />
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center h-24 w-24 rounded-full text-6xl font-bold ${savedTheme ? 'bg-blue-700 text-white' : 'bg-blue-50 text-blue-600'}`}>1</span>
                        </span>
                    </div>

                    <div className='text-2xl mt-8 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[0].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-blue-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[0].sales_score} ${props.renderer == "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>

                {/* Rank 3 */}
                <div className={`h-28 min-w-60 max-w-60 px-10 rounded-xl  relative bg-gradient-to-b ${savedTheme ? 'from-blue-800 via-neutral-950 to-black text-white' : 'from-blue-100 via-white to-white'}`}>
                    <div className='-mt-8 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-blue-900 bg-blue-600' : 'border-blue-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-8 inline-flex justify-center'>
                                {/* <img src={crown} width={'40px'} /> */}
                                <svg width="43" height="28" viewBox="0 0 43 28" fill="#2563eb" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.5374 11.8205C37.1968 11.6028 36.7832 11.4628 36.3437 11.4164C35.9042 11.37 35.4565 11.4192 35.0518 11.5584L27.5206 14.1232L23.1448 8.08155C22.9357 7.79948 22.6327 7.56491 22.2666 7.40172C21.9006 7.23853 21.4846 7.15256 21.0611 7.15256C20.6376 7.15256 20.2216 7.23853 19.8556 7.40172C19.4896 7.56491 19.1865 7.79948 18.9774 8.08155L14.6016 14.1232L7.07044 11.5584C6.66487 11.4194 6.21664 11.3702 5.77643 11.4162C5.33622 11.4621 4.92158 11.6015 4.57938 11.8185C4.23718 12.0355 3.98108 12.3216 3.84002 12.6442C3.69895 12.9669 3.67856 13.3134 3.78114 13.6444L7.5616 25.99C7.63389 26.229 7.76878 26.4542 7.9581 26.6522C8.14742 26.8501 8.38723 27.0165 8.663 27.1413C9.03635 27.3125 9.46322 27.4031 9.89834 27.4035C10.1099 27.4032 10.3203 27.3801 10.5235 27.3351C17.4143 25.8759 24.693 25.8759 31.5839 27.3351C32.2131 27.4618 32.8822 27.3921 33.4444 27.1413C33.7218 27.0181 33.9629 26.8521 34.1525 26.654C34.3421 26.4558 34.476 26.2297 34.5458 25.99L38.3411 13.6444C38.4425 13.3133 38.421 12.967 38.279 12.6447C38.137 12.3224 37.8801 12.0369 37.5374 11.8205Z" fill="#2563eb"/>
                                    <ellipse cx="21.0598" cy="4.0872" rx="4.95532" ry="4.0872" fill="#2563eb"/>
                                    <ellipse cx="39.0229" cy="10.7288" rx="3.09707" ry="2.5545" fill="#2563eb"/>
                                    <ellipse cx="3.09707" cy="10.7288" rx="3.09707" ry="2.5545" fill="#2563eb"/>
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center ${savedTheme ? 'bg-blue-700 text-white' : 'bg-blue-50 text-blue-600'} h-16 w-16 rounded-full text-5xl font-bold`}>3</span>
                        </span>
                    </div>

                    <div className='text-xl mt-4 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[2].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-blue-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[2].sales_score} ${props.renderer == "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>
            </div>
        )
    }


    // Vee Repairs
    if (props.theme == "sky-blue") {
        return (
            <div className={`flex justify-center items-end mt-36 relative`}>
				<div className='w-full flex justify-end overflow-hidden absolute -top-28 right-0'>
                    <div className='inline-block p-5 px-10 rounded-2xl rounded-r-none bg-sky-600 text-white vee-total-score'><span className='text-xl h-10 inline-block'>Total:</span> <br /> <span className='text-3xl inline-block h-10 -mt-10'>{ve_totals} Sales</span></div>
                </div>
                {/* Rank 2 */}
                <div className={`h-32 min-w-60 max-w-60 px-10 rounded-xl  relative bg-gradient-to-b ${savedTheme ? 'from-sky-800 via-neutral-950 to-black text-white' : 'from-sky-100 via-white to-white text-black'}`}>
                    <div className='-mt-8 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-sky-700 bg-sky-600' : 'border-sky-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-8 inline-flex justify-center'>
                                {/* <img src={crown} width={'40px'} /> */}
                                <svg width="43" height="28" viewBox="0 0 43 28" fill="#0284c7" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.5374 11.8205C37.1968 11.6028 36.7832 11.4628 36.3437 11.4164C35.9042 11.37 35.4565 11.4192 35.0518 11.5584L27.5206 14.1232L23.1448 8.08155C22.9357 7.79948 22.6327 7.56491 22.2666 7.40172C21.9006 7.23853 21.4846 7.15256 21.0611 7.15256C20.6376 7.15256 20.2216 7.23853 19.8556 7.40172C19.4896 7.56491 19.1865 7.79948 18.9774 8.08155L14.6016 14.1232L7.07044 11.5584C6.66487 11.4194 6.21664 11.3702 5.77643 11.4162C5.33622 11.4621 4.92158 11.6015 4.57938 11.8185C4.23718 12.0355 3.98108 12.3216 3.84002 12.6442C3.69895 12.9669 3.67856 13.3134 3.78114 13.6444L7.5616 25.99C7.63389 26.229 7.76878 26.4542 7.9581 26.6522C8.14742 26.8501 8.38723 27.0165 8.663 27.1413C9.03635 27.3125 9.46322 27.4031 9.89834 27.4035C10.1099 27.4032 10.3203 27.3801 10.5235 27.3351C17.4143 25.8759 24.693 25.8759 31.5839 27.3351C32.2131 27.4618 32.8822 27.3921 33.4444 27.1413C33.7218 27.0181 33.9629 26.8521 34.1525 26.654C34.3421 26.4558 34.476 26.2297 34.5458 25.99L38.3411 13.6444C38.4425 13.3133 38.421 12.967 38.279 12.6447C38.137 12.3224 37.8801 12.0369 37.5374 11.8205Z" fill="#0284c7"/>
                                    <ellipse cx="21.0598" cy="4.0872" rx="4.95532" ry="4.0872" fill="#0284c7"/>
                                    <ellipse cx="39.0229" cy="10.7288" rx="3.09707" ry="2.5545" fill="#0284c7"/>
                                    <ellipse cx="3.09707" cy="10.7288" rx="3.09707" ry="2.5545" fill="#0284c7"/>
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center ${savedTheme ? 'bg-sky-700 text-white' : 'bg-sky-50 text-sky-600'} h-16 w-16 rounded-full text-5xl font-bold`}>2</span>
                        </span>
                    </div>

                    <div className='text-xl mt-4 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[1].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-sky-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[1].sales_score} ${props.renderer == "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>

                {/* Rank 1 */}
                <div className={`h-40 mx-8 w-72 rounded-xl relative bg-gradient-to-b -mt-6 ${savedTheme ? 'from-sky-800 via-neutral-950 to-black text-white' : 'from-sky-100 via-white to-white'}`}>
                    <div className='-mt-14 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-sky-900 bg-sky-600' : 'border-sky-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-12 inline-flex justify-center'>
                                {/* <img src={trophy} width={'40px'} /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 128 128">
                                    <path fill="#fec417" d="M97.12 59.35c2.22-1.83 15.04-9.76 22.06-16.44c5.48-5.22 7.6-18.85-.83-25.48c-10.96-8.61-21.48.43-21.48.43s-.92-5.11-7.32-8.11c-8.06-3.78-18.27-5.19-27.66-5.07c-9.1.11-17.48 1.21-24.92 4.86c-6.66 3.27-7.06 7.93-7.06 7.93s-8.74-8.35-19.45-1.57C-.24 22.69 2.83 36.29 7.98 42c6.71 7.44 14.77 11.23 18.43 13.97c3.65 2.74 7.15 5.35 7.15 7.7s-1.04 2.87-1.44 2.74c-.39-.13-1.08-2.42-2.87-1.44c-2.44 1.35-1.7 6.79 3.39 7.31c4.93.51 5.87-4.7 5.87-4.7l.78-5.09l8.09 5.61l8.87 7.31l-.26 6.26s-.39 4.83-2.74 9.01s-6.13 8.35-6.13 8.35l-.12 3.78l32.89-.78l-1.04-3.65s-4.24-4.66-6.79-9.66c-1.82-3.58-2.11-7.19-2.11-7.19l-.08-8.98l17.07-10.46s1.83 1.44 1.57 1.96s-.32 5.85 3.78 7.83c4.05 1.96 7.31-.52 6.92-3.92c-.39-3.39-2.48-2.22-3.13-1.7s-2.09.65-2.48-1.31c-.4-1.94 1.3-3.77 3.52-5.6M13.09 40.89l-3.91-11.9l4.73-6.19l5.3-1.55s6.77 5.05 7.01 5.46s4.32 8.8 4.32 8.8l5.38 19.48zm76.86 15.65c.08-.41 5.05-20.87 5.05-20.87c0-.24 3.5-1.87 3.5-2.2s6.36-10.68 6.36-10.68l10.6.41l2.2 10.51l-5.3 8.72c.01.01-22.49 14.51-22.41 14.11" />
                                    <path fill="#ffa828" d="M119.92 30.64c-.78-9.32-10-11.55-13.79-10.2s-6.25 3.88-7.09 7.48c-.68 2.91-1.36 4.85-2.14 5.15c-.86.32-1.65.19-1.65.19s-.14-6.3-.1-8.84c.1-5.24 1.7-6.58 1.7-6.58s-.34-1.77-1.02-2.75c-.68-.97-1.94-2.23-1.94-2.23s.29 2.52-.97 3.11s-2.62-.29-2.62-.29l1.26 5.24s-2.04 26.12-2.23 26.7s-7.86 13.88-7.86 13.88s-4.47 4.18-6.6 5.15c-2.14.97-3.98 3.5-12.23 3.59c-8.25.1-15.34-6.41-15.34-6.41l-7.09-6.8l-5.24-15.44l-.78-15.73l.33-5.72l.23-2.39l-.37-1.21s-.68.68-1.94.39s-1.75-1.94-1.75-1.94s-.49.78-.58 1.17c-.1.39-.2 1.29-.2 1.29s1.36 1.43 1.65 3.47s-.1 10.49-1.55 10.29c-1.46-.19-.98-7.28-6.51-10c-4.79-2.36-11.94-1.65-14.86 4.27c-2.91 5.92-.78 12.91 8.35 19.42s18.85 11.98 20.39 17.2c1.84 6.21-2.33 9.9-2.33 9.9s3.03-.42 4.52-3.67c.48-1.05.66-2.58.73-3.49c3.45 4.5 8.04 8.31 14.12 10.15c2.3.79 4.89 1.19 8.49 1.19c.52 0 1.03-.02 1.54-.04c4.21-.15 7.81-.94 11.3-2.67c4.71-2.18 8.36-5.51 11.18-9.29c-.08.9.47 3.14 1.52 4.91c1.84 3.11 4.95 3.3 4.95 3.3s-4.27-2.52-3.4-8.54s4.85-8.16 10.87-12.04c6.04-3.89 19.82-11.85 19.05-21.17M21.27 43.85c-2.71-1.91-7.46-4.46-9.42-8.35c-2.61-5.19-.49-11.46 4.47-12.23c10.3-1.62 10.49 11.17 11.17 12.72s2.62 1.65 2.62 1.65s.07.86.35 2.52c.01.05.02.09.02.14l.03.15c.19 1.13.46 2.54.84 4.15l.03.12c.21.91.43 1.77.66 2.56c.46 1.66 1.02 3.45 1.71 5.29c-1-.68-6.73-4.65-12.48-8.72m93.31-7.19c-2.15 3.48-6.5 6.07-9.71 8.25c-4.14 2.81-10.3 7.03-12.04 8.21c.4-1.06.75-2.11 1.07-3.14c.52-1.36 1.07-3.02 1.45-4.81c1-4.7 1.17-8.81 1.17-8.81s2.04.58 3.3-.78s1.73-5.58 2.91-7.57c1.84-3.11 5.53-6.31 11.07-2.43c3.2 2.25 3.32 6.96.78 11.08M60.52 79.31l5.65-.07s.15 6.17 1.04 10.77c.89 4.61 2.08 9.73 2.08 9.73s-11.51 3.86-11.44-.3s1.78-12.26 1.78-12.26s1.04-9.65.89-7.87" />
                                    <path fill="#ffa828" d="M51.04 15.19s6.29-.71 12.14-.62s11.87 1.24 11.87 1.24s-.35 19.58-1.42 30.57c-1.06 10.99-5.22 19.22-6.33 21.55c-1.07 2.26-1.85 3.47-1.85 3.47l-5.64-.66l-9.75-19.58z" />
                                    <path fill="#ffefab" d="M43.51 18.12s1.77-.89 4.34-1.86c2.33-.88 4.43-1.15 4.43-1.15s.06 12.98.27 22.24c.18 7.89 3.1 20.82 4.52 24.28s3.98 8.74 3.98 8.74s-5.15-.74-8.32-4.76c-1.67-2.12-9.48-18.52-9.75-29.33s.79-18.43.53-18.16" />
                                    <path fill="#fffcfd" d="M40.58 19.62c-.18.53-1.59 11.7.62 22.06s5.48 17.45 7.64 20.26c1.92 2.49 3.17 3.67 3.88 3.67s-2.92-8.59-4.61-15.33c-1.68-6.73-2.57-14.8-2.75-18.78c-.18-3.99-.35-14.27-.35-14.27s-1.95.89-2.39 1.15c-.44.27-2.04 1.24-2.04 1.24" />
                                    <path fill="#ffefab" d="M53.34 99.01s3.81-6.2 4.61-10.9s1.24-8.86 1.24-8.86l2.36.1s-.23 6.81-.5 11.42s-1.06 9.13-1.06 9.13z" />
                                    <path fill="#d1701c" d="M56.27 75.41s1.77-1.73 6.64-1.73s6.96 1.87 6.96 1.87l.13 6.01s-3.24-1.01-7.18-1.06c-3.28-.04-6.81 1.18-6.81 1.18zM35 15.72c.82-.76 8.68-6.66 27.29-6.82c20.02-.18 27.53 5.62 28 5.94c.53.35 1.15 1.95.89 2.66c-.27.71-.8.97-2.3.35c-1.51-.62-8.95-6.05-26.85-5.49c-17.19.53-24.48 5.76-25.25 6.2c-.62.35-1.77.44-2.3-.27c-.54-.7-.01-2.07.52-2.57m56.18 4.17c-2.3.18-.71 18.7-5.58 30.3c-5.22 12.41-11.88 15.95-11.61 16.66s6.79-1.26 10.9-7.09c3.81-5.4 6.11-10.19 7.18-16.57c1.05-6.38 1.67-23.5-.89-23.3m-56.67 2.12c-.58-.03.01 9.21-.93 13.27c-.36 1.56-1.24 1.88-1.33 2.23s1.93 12.24 5.32 17.46c3.28 5.05 9.75 11.34 10.81 10.28s-4.7-4.61-8.59-12.32c-2.24-4.42-4.52-11.08-4.61-18.7c-.06-5.04.57-12.15-.67-12.22" />
                                    <path fill="#865c50" d="m86.77 112.71l-.01-14.29H40.89c.14 0 .03 11.62.01 14.34h-6.52v10.35h58.25v-10.35z" />
                                    <path fill="#fcc219" d="M55.59 105.93c-1.13-.05-2.43.18-2.48 1.58s-.05 5.45-.05 6.22s.63 1.44 2.21 1.49s16.41 0 17.45 0s1.58-.9 1.62-1.8c.05-.9-.05-4.69-.05-5.72c0-1.71-1.53-1.71-2.57-1.71c-1.12-.02-16.13-.06-16.13-.06" />
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center h-24 w-24 rounded-full text-6xl font-bold ${savedTheme ? 'bg-sky-700 text-white' : 'bg-sky-50 text-sky-600'}`}>1</span>
                        </span>
                    </div>

                    <div className='text-xl mt-5 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[0].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-sky-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[0].sales_score} ${props.renderer == "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>

                {/* Rank 3 */}
                <div className={`h-28 min-w-60 max-w-60 px-10 rounded-xl  relative bg-gradient-to-b ${savedTheme ? 'from-sky-800 via-neutral-950 to-black text-white' : 'from-sky-100 via-white to-white'}`}>
                    <div className='-mt-8 -translate-y-1 flex justify-center items-center'>
                        <span className={`relative inline-flex items-center justify-center p-0.5 rounded-full border-t ${savedTheme ? 'border-sky-900 bg-sky-600' : 'border-sky-700 bg-white'}`}>
                            <span className='w-full absolute left-0 -top-8 inline-flex justify-center'>
                                {/* <img src={crown} width={'40px'} /> */}
                                <svg width="43" height="28" viewBox="0 0 43 28" fill="#0284c7" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.5374 11.8205C37.1968 11.6028 36.7832 11.4628 36.3437 11.4164C35.9042 11.37 35.4565 11.4192 35.0518 11.5584L27.5206 14.1232L23.1448 8.08155C22.9357 7.79948 22.6327 7.56491 22.2666 7.40172C21.9006 7.23853 21.4846 7.15256 21.0611 7.15256C20.6376 7.15256 20.2216 7.23853 19.8556 7.40172C19.4896 7.56491 19.1865 7.79948 18.9774 8.08155L14.6016 14.1232L7.07044 11.5584C6.66487 11.4194 6.21664 11.3702 5.77643 11.4162C5.33622 11.4621 4.92158 11.6015 4.57938 11.8185C4.23718 12.0355 3.98108 12.3216 3.84002 12.6442C3.69895 12.9669 3.67856 13.3134 3.78114 13.6444L7.5616 25.99C7.63389 26.229 7.76878 26.4542 7.9581 26.6522C8.14742 26.8501 8.38723 27.0165 8.663 27.1413C9.03635 27.3125 9.46322 27.4031 9.89834 27.4035C10.1099 27.4032 10.3203 27.3801 10.5235 27.3351C17.4143 25.8759 24.693 25.8759 31.5839 27.3351C32.2131 27.4618 32.8822 27.3921 33.4444 27.1413C33.7218 27.0181 33.9629 26.8521 34.1525 26.654C34.3421 26.4558 34.476 26.2297 34.5458 25.99L38.3411 13.6444C38.4425 13.3133 38.421 12.967 38.279 12.6447C38.137 12.3224 37.8801 12.0369 37.5374 11.8205Z" fill="#0284c7"/>
                                    <ellipse cx="21.0598" cy="4.0872" rx="4.95532" ry="4.0872" fill="#0284c7"/>
                                    <ellipse cx="39.0229" cy="10.7288" rx="3.09707" ry="2.5545" fill="#0284c7"/>
                                    <ellipse cx="3.09707" cy="10.7288" rx="3.09707" ry="2.5545" fill="#0284c7"/>
                                </svg>
                            </span>
                            <span className={`inline-flex items-center justify-center ${savedTheme ? 'bg-sky-700 text-white' : 'bg-sky-50 text-sky-600'} h-16 w-16 rounded-full text-5xl font-bold`}>3</span>
                        </span>
                    </div>

                    <div className='text-xl mt-2 text-center'>{props.scores[2].sales_score <= 0 ? '--' : props.scores[2].name}</div>
                    <div className='w-full absolute bottom-0 left-0 flex items-center justify-center'><span className={`translate-y-5 min-w-28 text-center p-1 px-6 rounded-full border text-2xl bg-sky-600 text-white`}>{props.scores[2].sales_score <= 0 ? '0' : `${props.scores[2].sales_score} ${props.renderer == "fastranking" ? " Leads" : " Sales"}`}</span></div>
                </div>
            </div>
        )
    }
}

export default Ranks