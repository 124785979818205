import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Login() {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate()
  const [showAlert, setShowAlert] = useState(false);
  // useEffect(() => {
  // }, [user])

  const login = (e) => {
    e.preventDefault();
    axios.post("https://scorboardapi.fastranking.tech", 
      {
        "username": user,
        "password": password,
      }
    ).then((response) => {
      if(response.data.success){
        setShowAlert(false);
        navigate(`/update/${user}`);
        let credentials = {"username": user, "password": password};
        localStorage.setItem("user", JSON.stringify(credentials));
      }
    }).catch((err) => {
        setShowAlert(true)
    });
  }
  return (
    <div className='min-h-screen w-full flex justify-center items-center'>
      <div className='h-80 w-96'>
        <h1 className='text-center text-2xl'>Please Login</h1>
        <form onSubmit={login}>
    		<input type="text" className={`w-full bg-transparent p-2 mt-5 border border-gray-600`} onChange={(e) => setUser(e.target.value)} placeholder="User ID" />
            <input type="text" className={`w-full bg-transparent p-2 mt-5 border border-gray-600`} onChange={(e) => setPassword(e.target.value)} placeholder="User Password" />
            <input type="submit" className={`w-full p-2 mt-5 bg-white border text-black border-gray-600`} />
    	</form>
        <p className='text-red-600 text-center mt-10' style={{display: showAlert ? 'block' : 'none'}}>Invalid ID or PASSWORD</p>
      </div>
    </div>
  )
}

export default Login