import React from 'react'
import Navigation from '../Components/Navigation'
import { Link } from 'react-router-dom'

function ProcessDashboard() {
    return (
        <div className='w-full'>
            <Navigation />

            {/* All Processes */}
            <div className='w-full min-h-96 shadow-2xl shadow-gray-50 rounded-2xl bg-white border-2 border-purple-200 p-8 pt-6 mt-20'>
                <div className='w-full p-2 px-4 pt-0 grid grid-cols-3'>
                    <div className='p-2 text-xl font-semibold'>Process Name</div>
                    <div className='p-2 text-xl font-semibold text-center'>Total Score</div>
                    <div className='p-2 text-xl font-semibold  text-end'>Action</div>
                </div>


                <div className='w-full p-1.5 border-2 border-purple-200 bg-gray-50 rounded-xl mb-4 grid grid-cols-3'>
                    <div className='p-2 text-xl'>Fast Ranking</div>
                    <div className='p-2 text-xl text-center'>{0}</div>
                    <div className='p-2 text-xl  text-end'><Link to={'/update/fast-ranking'}>View</Link></div>
                </div>

                <div className='w-full p-1.5 border-2 border-purple-200 bg-gray-50 rounded-xl mb-4 grid grid-cols-3'>
                    <div className='p-2 text-xl'>Sparta Telecom</div>
                    <div className='p-2 text-xl text-center'>{0}</div>
                    <div className='p-2 text-xl  text-end'><Link to={'/update/sparta'}>View</Link></div>
                </div>

                <div className='w-full p-1.5 border-2 border-purple-200 bg-gray-50 rounded-xl mb-4 grid grid-cols-3'>
                    <div className='p-2 text-xl'>Vee Repairs</div>
                    <div className='p-2 text-xl text-center'>{0}</div>
                    <div className='p-2 text-xl  text-end'><Link to={'/update/vee'}>View</Link></div>
                </div>

            </div>
        </div>
    )
}

export default ProcessDashboard