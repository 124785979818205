import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function ProcessScores(props) {
  const savedTheme = useSelector((state) => state.darkTheme);
  const allEmployees = props.records;

  const [defaulTheme, setDefaultTheme] = useState({text: 'purple-600'});
  // useEffect(() => {
  //   if(props.theme == "purple"){
  //     setDefaultTheme(
  //       (values) => 
  //       (
  //         {...values, text_1: 'text-purple-600'},
  //         {...values, text_2: 'text-purple-600'},
  //         {...values, text_3: 'text-purple-600'}
  //       ))
  //   }
  // })
  if(props.theme == "purple"){
    return (
      <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6`}>
        <div className='grid grid-cols-2'>
          <div className={`mr-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
  
            <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold`}>
              <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
              <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
              <div className='p-1 text-end mr-5'>Score</div>
            </div>
            {
              allEmployees.map((value, key) => {
                if(allEmployees[2].score > 0){
                  if (key >= 3 && key < 8){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
                else{
                  if (key >= 0 && key < 5) {
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </div>
  
  
          <div className={`ml-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
            <div className='w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold'>
              <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
              <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
              <div className='p-1 text-end mr-5'>Score</div>
            </div>
            {
              allEmployees.map((value, key) => {
                if(allEmployees[2].score > 0){
                  if(key > 7 && key < 12){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
  
                else{
                  if(key > 4 && key < 10){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </div>
        </div>
      </div>
    )
  }


  // Sparta Telecome
  if(props.theme == "blue"){
    return (
      <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6`}>
        <div className='grid grid-cols-2'>
          <div className={`mr-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
  
            <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold`}>
              <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
              <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
              <div className='p-1 text-end mr-5'>Score</div>
            </div>
            {
              allEmployees.map((value, key) => {
                if(allEmployees[2].score > 0){
                  if (key >= 3 && key < 8){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
                        <div className='p-2 w-24 text-center bg-blue-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
                        <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
                else{
                  if (key >= 0 && key < 5) {
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
                        <div className='p-2 w-24 text-center bg-blue-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
                        <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </div>
  
  
          <div className={`ml-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
            <div className='w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold'>
              <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
              <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
              <div className='p-1 text-end mr-5'>Score</div>
            </div>
            {
              allEmployees.map((value, key) => {
                if(allEmployees[2].score > 0){
                  if(key > 7 && key < 12){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-blue-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
  
                else{
                  if(key > 4 && key < 10){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-blue-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </div>
        </div>
      </div>
    )
  }


  // Vee Repairs
  if(props.theme == "sky-blue"){
    return (
      <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6`}>
        <div className='grid grid-cols-2'>
          <div className={`mr-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
  
            <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold`}>
              <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
              <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
              <div className='p-1 text-end mr-5'>Score</div>
            </div>
            {
              allEmployees.map((value, key) => {
                if(allEmployees[2].score > 0){
                  if (key >= 3 && key < 8){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
                        <div className='p-2 w-24 text-center bg-sky-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
                        <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
                else{
                  if (key >= 0 && key < 5) {
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
                        <div className='p-2 w-24 text-center bg-sky-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
                        <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </div>
  
  
          <div className={`ml-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
            <div className='w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold'>
              <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
              <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
              <div className='p-1 text-end mr-5'>Score</div>
            </div>
            {
              allEmployees.map((value, key) => {
                if(allEmployees[2].score > 0){
                  if(key > 7 && key < 12){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-sky-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
  
                else{
                  if(key > 4 && key < 10){
                    return (
                      <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
                        <div className='p-2 pt-2 w-24 text-center bg-sky-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
                        <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
                        <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ProcessScores