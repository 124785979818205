import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import FastRanking from './Processes/FastRanking';
import Sparta from './Processes/Sparta';
import Vee from './Processes/Vee';
import ProcessDashboard from './Pages/ProcessDashboard';
import SpartaScores from './Pages/UpdatesScores/SpartaScores';
import FastRankingScore from './Pages/UpdatesScores/FastRankingScore';
import VeeRepairsScore from './Pages/UpdatesScores/VeeRepairsScore';
import Layout from './Components/Layout';
import AllProcesses from './Processes/AllProcesses';
import Login from './Pages/Login';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* <Route path='/' element={<Home />} /> */}
          <Route path='/' element={<AllProcesses />} />
          <Route path='/admin-login' element={<Login />} />
          {/* <Route path='/fastranking' element={<FastRanking />} />
          <Route path='/sparta' element={<Sparta />} />
          <Route path='/telecom' element={<Sparta />} />
          <Route path='/sparta-telecom' element={<Sparta />} />
          <Route path='/vee' element={<Vee />} />
          <Route path='/vee-repairs' element={<Vee />} /> */}

          <Route path='/all-process' element={<ProcessDashboard />} />
          <Route path='/update/fastranking' element={<FastRankingScore />} />
          <Route path='/update/sparta' element={<SpartaScores />} />
          <Route path='/update/veerepairs' element={<VeeRepairsScore />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;