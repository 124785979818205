import React, { useEffect, useState } from 'react'
import Navigation from '../../Components/Navigation'
import { Link } from 'react-router-dom'
import fastranking_logo from '../../Assets/Logos/Fast Ranking logo Color.svg'
import fastranking_logo_lite from '../../Assets/Logos/Fast Ranking logo White.svg'
import { useSelector } from 'react-redux';
import axios from 'axios'

function FastRankingScore() {

    const savedTheme = useSelector((state) => state.darkTheme);

    const [employees, setEmployees] = useState([]); // Ensure employees is an array initially
    const [allEmployees, setAllEmployees] = useState([]);
  	const deleteUser = (userId) => {
    	let delete_conf = window.confirm("Do You Want to Delete?")
        if(delete_conf === true){
           axios.get(`https://scorboardapi.fastranking.tech/delete_fr/${userId}`).then((res) => window.location.reload()).catch((err) => console.log(err))
           }
    }
    useEffect(() => {
        axios.get("https://scorboardapi.fastranking.tech/fastranking")
            .then((response) => {
                if (response.data && Array.isArray(response.data.data)) {
                    setEmployees(response.data.data);
                    setAllEmployees(response.data.data);
                    console.log(response.data.data);
                } else {
                    console.log('Unexpected response structure:', response.data);
                    setEmployees([]);
                }
            })
            .catch((err) => {
                console.error('Error fetching data:', err);
                setEmployees([]); // Ensure employees remains an array even if there's an error
            });
    }, []);
  
  	const updateScore = () => {
    	axios.get("https://scorboardapi.fastranking.tech/fastranking")
          .then((response) => {
          if (response.data && Array.isArray(response.data.data)) {
            setEmployees(response.data.data);
            setAllEmployees(response.data.data);
            console.log(response.data.data);
          } else {
            console.log('Unexpected response structure:', response.data);
            setEmployees([]);
          }
        })
          .catch((err) => {
          console.error('Error fetching data:', err);
          setEmployees([]); // Ensure employees remains an array even if there's an error
        });
      
      
      window.location.reload()
    };
    
    const [userVerfied, setUserVerfied] = useState(false);
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            axios.post("https://scorboardapi.fastranking.tech/", user)
                .then((response) => {
                    if(user.username == "fastranking"){
                        setUserVerfied(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    useEffect(() => {
        if (allEmployees.length > 0) {
            let updated_details = [...allEmployees].sort((a, b) => b.score - a.score);
            localStorage.setItem('fastranking_employees', JSON.stringify(updated_details));
            setAllEmployees(updated_details);
        }
    }, []);

    const [addEmployeeForm, setAddEmployeeForm] = useState(false);
    const [employee, setEmployee] = useState({
        name: '',
        process: 'telecom',
        score: 0,
        id: Math.random() * 100 + Math.random() * 100
    });

    const handleNewEmployee = (e) => {
        e.preventDefault();

        if (employee.name.trim() !== '') {
            setAllEmployees([...allEmployees, employee]);
            setEmployee({ name: '', process: 'telecom', score: 0, id: Math.random() * 100 + Math.random() * 100 });
        }
        setAddEmployeeForm(false);

        // Post Request
        axios.post("https://scorboardapi.fastranking.tech/create_employee_fr", 
            { emp_name: employee.name, score: employee.score }
        ).then((response) => {
            console.log(response.data);
            setEmployee(response.data.data);
            window.location.reload()
        })
        .catch((err) => console.log(err));
    };

    const updtaeEmployeeScore = (id, score) => {
        axios.post(`https://scorboardapi.fastranking.tech/update_fr/${id}`, {"score": score}).then((response) => console.log(id)).catch((err) => console.log(err))
    }

    if (userVerfied) {
        return (
            <div className='w-full'>
                <Navigation
                    logo={fastranking_logo}
                    logoLite={fastranking_logo_lite}
                    width={'170px'}
                />

                <div className={`relative w-4/5 m-auto min-h-96 shadow-2xl rounded-2xl border-2 p-8 pt-6 mt-20 mb-10 ${savedTheme ? 'bg-black border-purple-800' : 'bg-white shadow-gray-50 border-purple-400'}`}>
                    <div className='w-full bg-red-600'>
                        <button className='bg-purple-600 text-white absolute -top-14 rounded-full right-44 p-2 px-6' onClick={() => setAddEmployeeForm(!addEmployeeForm)}>Add New Employee</button>
                        <button onClick={updateScore} className='bg-green-600 text-white absolute -top-14 rounded-full right-0 p-2 px-6'>Update Score</button>
                    </div>
                    <div className='w-full p-2 px-4 pt-0 grid grid-cols-3 mb-4'>
                        <div className='p-2 text-base font-semibold'>Agent Name</div>
                        <div className='p-2 text-base font-semibold text-center'>Current Score</div>
                        <div className='p-2 text-base font-semibold text-end'>New Score</div>
                    </div>

                    {
                        employees && employees.length > 0 ? 
                        allEmployees.map((value, key) => (
                            <div key={key} className={`w-full p-1 border shadow-xl rounded-xl mb-4 grid grid-cols-3 items-center ${savedTheme ? 'bg-neutral-900 border-neutral-800' : 'shadow-gray-100'}`}>
                                <div className='p-2 text-base pl-4'>{value.name}</div>
                                <div className='p-2 text-base text-center'>{value.score}</div>
                                <div className="flex justify-end">
									<div className='text-base text-end'>
                                      <input className={`w-20 text-base p-0.5 px-4 text-center rounded-full mr-2 ${savedTheme ? 'bg-black' : 'bg-gray-200'}`}
                                          onChange={(e) => {                
                                              const newScore = e.target.value;
                                              updtaeEmployeeScore(value.id, newScore)
                                              
                                          }}
                                          onKeyDown={(e) => {

                                              if (
                                                !(
                                                  (e.key >= '0' && e.key <= '9') ||
                                                  e.key === 'Backspace' ||
                                                  e.key === 'Delete' ||
                                                  e.key === 'ArrowLeft' ||
                                                  e.key === 'ArrowRight' ||
                                                  e.key === 'Tab'
                                                )
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                          type='number' defaultValue={value.score} min="0"/>
                                  </div>
                                  <button className="h-8 w-8 inline-flex justify-center items-center hover:bg-gray-800 cursor-pointer bg-gray-700 rounded-full" onClick={() => {deleteUser(value.id)}}>
									<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
	<path fill="#fff" d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z" />
</svg>
									</button>
								</div>
                            </div>
                        )) : <div className='pl-6 inline-block text-red-600'>No Employees Found!</div>
                    }

                </div>

                {addEmployeeForm && (
                    <div className='h-screen w-screen fixed flex justify-center items-center top-0 left-0 bg-black bg-opacity-50'>
                        <form className='h-80 w-96 p-8 bg-white rounded-2xl' onSubmit={handleNewEmployee}>
                            <h1 className='text-xl mb-5 text-center mt-5'>Fast Ranking</h1>

                            <input className='w-full mr-2 border rounded-full p-2 px-5' type='text'
                                onChange={(e) => setEmployee((values) => ({ ...values, name: e.target.value }))}
                                placeholder='Employee Name' />
                            <input className='w-full border rounded-full mt-3 p-2 px-5' type='number' value={employee.score}
                                onChange={(e) => setEmployee((values) => ({ ...values, score: e.target.value }))}
                                placeholder='Current Sales' />
                            <div className='flex mt-5'>
                                <input className='cursor-pointer w-full p-2 px-5 mr-4 bg-gray-100 rounded-full' onClick={() => setAddEmployeeForm(false)} type='reset' value={'Cancel'} />
                                <input className='cursor-pointer w-full p-2 px-5 bg-green-500 text-white rounded-full' type='submit' value={'Confirm Add'} />
                            </div>
                        </form>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div className='w-full'>
                <Navigation
                    logo={fastranking_logo}
                    logoLite={fastranking_logo_lite}
                    width={'170px'}
                />
                <p className='text-center pt-40'><Link to="/admin-login">Please Login</Link></p>
            </div>
        );
    }
}

export default FastRankingScore;
