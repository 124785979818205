import { createSlice } from "@reduxjs/toolkit";

const theme = localStorage.getItem("darkTheme") ? JSON.parse(localStorage.getItem("darkTheme")) : false
const initialState = {
    darkTheme: theme
}

const ThemeSlice = createSlice({
    name: 'ThemeSlice',
    initialState: initialState,
    reducers: {
        setTheme: (state, action) => {
            let themeMode = action.payload;
            state.darkTheme = themeMode;
            localStorage.setItem("darkTheme", JSON.stringify(themeMode))
        }
    }
})
export const { setTheme } = ThemeSlice.actions;
export default ThemeSlice.reducer;